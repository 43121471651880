<script>
import Layout from "../layouts/auth";
import Languages from "@/components/widgets/languages";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      "Your new bitcoin wallet has been confirmed.":
        "Sua nova carteira de bitcoin foi confirmada.",
      "The token is invalid.": "O token é inválido.",
      "Sign In here": "Entrar",
    },
    es: {
      "Your new bitcoin wallet has been confirmed.":
        "Su nueva cartera bitcoin ha sido confirmada.",
      "The token is invalid.": "El token no es válido.",
      "Sign In here": "Identifícate aquí",
    },
  },
  components: {
    Layout,
    Languages,
  },
  data() {
    return {
      loading: true,
      success: false,
      token: null,
    };
  },
  methods: {
    getBitcoin() {
      api.get("bitcoin/confirm/" + this.token).then((response) => {
        if (response.data.status == "success") {
          this.success = true;
        } else {
          this.success = false;
        }
      });

      this.loading = false;
    },
  },
  mounted() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
      this.getBitcoin();
    }
  },
};
</script>

<template>
  <Layout>
    <div
      id="auth"
      class="row m-0 flex-column-reverse flex-sm-row align-items-center"
    >
      <div class="col">
        <div class="login-content">
          <div class="pt-5 text-center">
            <router-link class="align-middle" tag="a" to="/">
              <img
                class="w-100"
                style="max-width: 180px"
                src="@/assets/images/logo.png"
              />
            </router-link>
          </div>
          <div class="pt-5">
            <div v-if="loading" class="text-center">
              <b-spinner small variant="default" role="status"></b-spinner>
            </div>
            <div v-else>
              <div v-if="success" class="p-4 text-center">
                {{ t("Your new bitcoin wallet has been confirmed.") }}
              </div>
              <div v-else class="p-4 text-center">
                {{ t("The token is invalid.") }}
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 text-center">
          <p class="text-white">
            <router-link
              tag="a"
              to="/"
              class="font-weight-medium text-default"
              >{{ t("Sign In here") }}</router-link
            >
          </p>
          <Languages type="dropup"></Languages>
        </div>
      </div>
    </div>
  </Layout>
</template>